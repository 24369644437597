<template>
    <div style="text-align: left">
      <article>
        <section>
          <div class="container mt-5">
            <div class="mb-3">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/hotels' }">Hotels</el-breadcrumb-item>
                <el-breadcrumb-item>Add Hotel</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
            <h1
              class="mb-4"
              style="font-size: 1.1em; font-weight: 600;"
            >Add Hotel</h1>
            <div v-loading="isUploadingFile || isLoading">
              <el-form
                :model="hotelForm"
                :rules="hotelRules"
                ref="hotelForm"
                class="demo-hotelForm2"
              >
                <div class="row">
                  <div class="col-md-6">
                    <label class="mt-3 mb-2">Hotel Name</label>
                    <el-form-item prop="title">
                      <el-input v-model="hotelForm.title" placeholder="Hotel Name"></el-input>
                    </el-form-item>
                  </div>
                  <div class="col-md-6" v-if="$store.state.currentRole == 'ADMIN'">
                    <label class="mt-3 mb-2">Country</label>
                    <el-form-item prop="destination_country" >
                      <el-select
                        v-model="hotelForm.destination_country"
                        placeholder="Country of Destination"
                        style="width: 100%"
                        @change="getCountryPackages(hotelForm.destination_country)"
                      >
                       
                        <el-option 
                          v-for="country in countries"
                          :key="country.id"
                          :label="country.country_title"
                          :value="country.id"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
  
                    
  
                    
                    
                  </div>
                </div>
  
                <div class="row">
                  
                  
                  <div class="col-md-12">
                    <label class="mt-3 mb-2">Attach Safari Package(s)</label>
                    <el-form-item prop="packageIds">
                      <el-select
                        v-model="hotelForm.packageIds"
                        multiple=true
                        placeholder="Choose Safari Package"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="typ in safari_packages"
                          :key="typ.id"
                          :label="typ.title"
                          :value="typ.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
  
                <div class="row">
                  <div class="col-md-12">
                    <label class="mt-3 mb-2">Hotel Image</label><br />
                    <el-form-item>
                      <el-upload
                        class="upload-demo"
                        drag
                        action="''"
                        :on-change="handlePreview"
                        :on-remove="handleRemove"
                        :file-list="fileLists"
                        :auto-upload="false"
                        :multiple="false"
                      >
                        <div v-if="!isUploadingFile">
                          <i class="el-icon-upload"></i>
                          <div class="el-upload__text">
                            Drop file here or <em>click to upload</em>
                          </div>
                        </div>
                        <div v-else>
                          <el-progress
                            v-if="imageUploadingProgress != 100"
                            type="circle"
                            :color="progressColors"
                            :percentage="imageUploadingProgress"
                            :width="80"
                            :stroke-width="4"
                          ></el-progress>
                          <el-progress
                            v-else
                            type="circle"
                            :percentage="100"
                            status="success"
                            :width="80"
                            :stroke-width="4"
                          ></el-progress>
                          <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                            {{
                            imageUploadingProgress == 100
                              ? "Uploaded"
                              : "Uploading..."
                          }}
                          </div>
                        </div>
                        <!-- <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div> -->
                      </el-upload>
                    </el-form-item>
                  </div>
                </div>
  
                <div class="row">
                  <div class="col-md-12">
                    <label class="mt-3 mb-2">Hotel Description</label><br />
                    <el-form-item prop="brief_description">
                      <editor
                        api-key="m5t9lo16on3qgnsrn3mfypfsi3y72guxr20ma1iqjxrhnzt1"
                        :init="{
                          height: 300,
                          menubar: false,
                          plugins: [
                            'a11ychecker','advlist','advcode','advtable','autolink','checklist','export',
                            'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks',
                            'powerpaste','fullscreen','formatpainter','insertdatetime','media','table','help','wordcount'
                          ],
                          toolbar:
                            'undo redo | casechange blocks | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlst checklist outdent indent | removeformat | a11ycheck code table help'
                        }"
                        placeholder="Detailed Hotel description"
                        v-model="hotelForm.brief_description"
                      />
                    </el-form-item>
                  </div>
                </div>
  
  
                <hr />
                <el-form-item class="text-center">
                  <el-button
                    type="primary"
                    @click="addSafariPackage('hotelForm')"
                  >Add Hotel</el-button>
                </el-form-item>
              </el-form>
  
              <div class="text-center">
                <div class="text-center">
                  <small><p v-text="'\u00A9 ' + new Date().getFullYear() + ' Ihamba Adventures, All rights reserved.'"></p>
</small>
                </div>
                <div class="text-center">
                  <!--<small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                </div>
              </div>
            </div>
          </div>
        </section>
      </article>
    </div>
  </template>
  
  
  <script>
  import jwt_decode from "jwt-decode";
  
  // import BreadCrumbComponent from "../components/bread-crumb-component";
  import Editor from "@tinymce/tinymce-vue";
  // import TextEditor from "../reusable-text-editor/reusable-text-editor-component.vue";
  export default {
    components: {
      editor: Editor,
      // BreadCrumbComponent,
    },
    data() {
      return {
        addPackageModal: false,
        selectedFile: null,
        isUploadingFile: false,
        isLoading: false,
        value: "",
        content: "",
        loading: false,
        loadingError: false,
        imageUploadingProgress: 0,
        progressColors: [
          { color: "#f56c6c", percentage: 25 },
          { color: "#e6a23c", percentage: 50 },
          { color: "#1989fa", percentage: 75 },
          { color: "#6f7ad3", percentage: 100 },
        ],
        hotelForm: {
          title: "",
          amount: "",
          packageIds: [],
          destination_country: "",
          location: "Southern Uganda",
    
          brief_description: "",
   
        },
        fileLists: [],
        countries: [],
  
        safari_packages:[],
  
        hotelRules: {
          title: [
            {
              required: true,
              message: "Name is required",
              trigger: "blur",
            },
          ],
          amount: [
            {
              required: true,
              message: "Amount is required",
              trigger: "blur",
            },
          ],
       
          brief_description: [
            {
              required: true,
              message: "brief_description is required",
              trigger: "blur",
            },
          ],
  
          
        },
      };
    },
  
    mounted() {
      this.getCountries();
      this.getSafariTypes();
      this.setCurrentUser();
    },
  
    methods: {
      handleClose(done) {
        done();
      },
  
      handlePreview(file) {
        console.log(file.raw);
        this.fileLists = [file];
        this.selectedFile = file.raw;
      },
      handleRemove() {
        this.selectedFile = null;
      },

  
      setCurrentUser() {
        let userToken = JSON.parse(localStorage.getItem("user"));
        let decoded = jwt_decode(userToken);
        this.$store.commit("setCurrentUsername", decoded.data.username);
        this.$store.commit("setCurrentFullName", decoded.data.fullname);
        this.$store.commit("setCurrentRole", decoded.data.role);
        this.$store.commit("setCurrentPhoto", decoded.data.image);
      },
  
      async addSafariPackage(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            this.isUploadingFile = true;
            if (this.selectedFile == null) {
              this.$notify({
                title: "Empty",
                message: "Profile picture is empty",
                type: "warning",
              });
            }
  
            try {
              this.isUploadingFile = true;
              const formData = new FormData();
              formData.append("title", this.hotelForm.title);
              formData.append("image", this.selectedFile);
              
              formData.append(
                "destination_country", this.$store.state.currentRole == 'ADMIN' ? this.hotelForm.destination_country : this.$store.state.currentCountry
        
              );
              formData.append("safari_type", this.hotelForm.safari_type);
              formData.append(
                "brief_description",
                this.hotelForm.brief_description
              );
              formData.append(
                "inclusions_and_exclusions",
                this.hotelForm.inclusions_and_exclusions
              );
  
  
                 formData.append(
                "added_by",
               this.$store.state.userId
              );
              formData.append(
                "getting_there",
                this.hotelForm.getting_there
              );
              let request = await this.$http.post(
                `api/safari/package/add`,
                formData,
                {
                  onUploadProgress: (progressEvent) => {
                    this.imageUploadingProgress = +(
                      (progressEvent.loaded / progressEvent.total) *
                      100
                    ).toFixed(0);
                  },
                }
              );
  
  
  
  
              
              if (
                request.data.success &&
                request.data.message == "safari_tour added successfully"
              ) {
                this.addPackageModal = false;
                this.$router.push({ path: "/safari-packages" });
                this.$notify({
                  title: "Success",
                  message: "Package Added Successfully",
                  type: "success",
                });
              } else if (request.data.message == "safari_tour  exists") {
                this.$notify({
                  title: "Duplicate",
                  message: "Package Exists",
                  type: "warning",
                });
              } else throw "UNEXPECTED_RESPONSE";
            } catch (error) {
              if (error.message == "NetworkError") {
                this.isUploadingFile = false;
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred please try again",
                  type: "warning",
                });
              }
              this.isUploadingFile = false;
              this.$notify({
                title: "Upload Failed",
                message: "Unable to Upload profile picture now, Please try again",
                type: "error",
              });
            } finally {
              this.isUploadingFile = false;
            }
          } else {
            return false;
          }
        });
      },
  
      async getCountries() {
        this.isLoading = true;
        this.isLoadingError = false;
        try {
          //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
          let request = await this.$http.get(
            `api/safari/destination/country/get_all/admin`
          );
          if (request.data.success) {
            this.countries = request.data.countries;
          } else throw "UNEXPECTED_RESPONSE_RECEIVED";
        } catch (error) {
          this.isLoadingError = true;
          //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
          this.$notify({
            title: "Operation Failed",
            message: "Unable to fetch countries now, please try again",
            type: "error",
          });
        } finally {
          this.isLoading = false;
        }
      },
  
        async getCountryPackages(country_id){
            
            this.isLoading = true;
            this.isLoadingError = false;
            try {
                let request = await this.$http.get(`safari_tours/get_tours_by_country_back_office?id=${country_id}`);
          if (request.data.success) {
            this.safari_packages = request.data.safari_tours;
          }

            }catch (error){
                this.isLoadingError = true;
          //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
          this.$notify({
            title: "Operation Failed",
            message: "Unable to fetch countries now, please try again",
            type: "error",
          });
            }finally{
                this.isLoading = false;
            }
        },
      async getSafariTypes() {
        this.isLoading = true;
        this.isLoadingError = false;
        try {
          //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
          let request = await this.$http.get(`api/safari_types/get_all`);
          if (request.data.success) {
            this.safari_types = request.data.safari_types;
          } else throw "UNEXPECTED_RESPONSE_RECEIVED";
        } catch (error) {
          this.isLoadingError = true;
          //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
          this.$notify({
            title: "Operation Failed",
            message: "Unable to fetch safariTypes now, please try again",
            type: "error",
          });
        } finally {
          this.isLoading = false;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .addBtn {
    background-color: green;
    color: white;
  }
  input[type="text"],
  select,
  textarea {
    width: 100%;
    padding: 10px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    background-color: #ffffff;
    font-size: 0.9em;
  }
  
  label{
    font-size: 0.9em; font-weight: 600; color:#2a5e42
  }
  
  .search_add_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .search_by_input {
    width: 400px !important;
  }
  
  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
  }
  
  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  }
  
  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .search_add_section {
      display: block;
    }
    .addbtn {
      width: 100%;
      margin-top: 10px;
    }
    .search_by_input {
      width: 100% !important;
    }
  }
  
  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .search_add_section {
      display: block;
    }
    .addbtn {
      width: 100%;
      margin-top: 10px;
    }
    .search_by_input {
      width: 100% !important;
    }
  }
  </style>
  
  <style>
  .safari_dialogs .el-dialog {
    width: 60%;
  }
  
  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
    .safari_dialogs .el-dialog {
      width: 60%;
    }
  }
  
  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .safari_dialogs .el-dialog {
      width: 70%;
    }
  }
  
  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .safari_dialogs .el-dialog {
      width: 90%;
    }
  }
  
  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .safari_dialogs .el-dialog {
      width: 90%;
    }
  }
  </style>
  